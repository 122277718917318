<template>
  <div id="musc-theory-test-content-box">
    <div class="music-theory-test-question">
      <div class="question-box">
        <QuestionIntro :info="TestPaper"></QuestionIntro>

        <div class="question-content">
          <div class="question-tigan">
            {{
              TestPaper.question_num +
              "，" +
              TestPaper.questionIntro.questionTitle
            }}
          </div>

          <div class="stave">
            <div @click="getImage" id="stave-paper"></div>
          </div>

          <FontOptionsCard :info="TestPaper"></FontOptionsCard>
        </div>

        <ScoreBox :info="TestPaper"></ScoreBox>
      </div>
    </div>

    <div class="tips">
      <t-icon
        v-if="!isOpenTips"
        name="help-circle-filled"
        size="24"
        color="rgb(130,23,90)"
        @click="isOpenTips = true"
        style="cursor: pointer"
      ></t-icon>
      <KnowledgeBase
        :info="TestPaper"
        :isOpen="isOpenTips"
        @close="isOpenTips = false"
        :showOverlay="false"
      >
      </KnowledgeBase>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import QuestionIntro from "@/components/MusicTheoryComponents/components/QuestionIntro";
import { theme_color } from "@/tools/MusicTheoryTools";
import ScoreBox from "@/components/MusicTheoryComponents/components/ScoreBox.vue";
import FontOptionsCard from "@/components/MusicTheoryComponents/components/FontOptionsCard.vue";
import KnowledgeBase from "@/components/MusicTheoryComponents/KnowledgeBox/KnowledgeBase.vue";

export default {
  props: ["info"],
  watch: {
    info: {
      handler(n) {
        this.TestPaper = n;
      },
      deep: true,
      immediate: true,
    },
  },
  components: {
    ScoreBox,
    QuestionIntro,
    FontOptionsCard,
    KnowledgeBase,
  },
  data() {
    return {
      TestPaper: null,
      theme_color: theme_color,
      isOpenTips: true,
    };
  },
  mounted() {
    this.TestPaper.InitQuestion();
  },
};
</script>

<style lang="less" scoped>
@import url("/src/style/root.less");
@import url("/src/style/music_theory.less");

.stave {
  overflow: hidden;
  width: auto;
  margin: var(--pc-margin) 0;
  box-sizing: border-box;
  height: 160px;
  display: flex;
  align-items: center;
}
</style>
