<template>
    <t-dialog width="480" top="56px" :showInAttachedElement="true" draggable mode="modeless" :footer="false"
        v-model:visible="isOpenTips" :showOverlay="showOverlay" :preventScrollThrough="false">
        
        <template #header>
            <span style="display: flex; align-items: center;"><t-icon name="pin-filled"
                    style="color: #f80000;"></t-icon> 讲解与提示</span>
        </template>

        <template #body>
            <div class="knowledge-content-aside">
                <KnowledgeContainer 
                :info="info"
                :isOpen="isOpenTips"
                :activatePage="activatePage" 
                v-if="(info && info.askfor && info.askfor.includes('识别')) || (info && info.askfor && info.askfor.includes('听辩')) ||
                      activatePage == '/musicFoundation/DynamicMarkings' || activatePage == '/primary/PrimaryShiChangSheets'"
                
                ></KnowledgeContainer>
                <TonicSolfaKnowledge v-if="activatePage == '/musicFoundation/TonicSolfa'"></TonicSolfaKnowledge>
                <NotePitchNameKnowledge v-if="activatePage == '/musicFoundation/NotePitchName'">
                </NotePitchNameKnowledge>
                <NotePitchSingKnowledge v-if="activatePage == '/musicFoundation/NotePitchSing'">
                </NotePitchSingKnowledge>
                <NotePitchTwelveEqualLawNameKnowledge v-if="activatePage == '/musicFoundation/NotePitchTwelveEqualLawName'">
                </NotePitchTwelveEqualLawNameKnowledge>
                <NotePitchTwelveEqualLawSingKnowledge v-if="activatePage == '/musicFoundation/NotePitchTwelveEqualLawSing' || activatePage == '/musicFoundation/SeniorPitchSingRecognition'">
                </NotePitchTwelveEqualLawSingKnowledge>
                <NoteRhythmNameKnowledge v-if="activatePage == '/musicFoundation/NoteRhythmName' || activatePage == '/musicFoundation/NoteRhythmCount'">
                </NoteRhythmNameKnowledge>
                <TempoMarkKnowledge v-if="activatePage == '/musicFoundation/TempoMark'"></TempoMarkKnowledge>
                <ListeningRhythmExerciseKnowledge v-if="activatePage == '/musicFoundation/ListeningRhythmExercise'">
                </ListeningRhythmExerciseKnowledge>
                <NoteIntervalKnowledge v-if="activatePage == '/musicFoundation/NoteInterval'"></NoteIntervalKnowledge>
                <NoteInterval12LowKnowledge v-if="activatePage == '/musicFoundation/NoteInterval12Low'"></NoteInterval12LowKnowledge>
                <NoteIntervalSeniorKnowledge v-if="activatePage == '/musicFoundation/NoteIntervalSenior'"></NoteIntervalSeniorKnowledge>
                <ModeNameKnowledge v-if="activatePage == '/musicFoundation/ModeName' || activatePage == '/musicFoundation/SeniorModeNameRecognition'">
                </ModeNameKnowledge>
                <KeyBoard v-if="
                    activatePage == '/musicFoundation/ListeningPitchPrimary' ||
                    activatePage == '/musicFoundation/ListeningIntervalPrimary' ||
                    activatePage == '/musicFoundation/ListeningHarmonyPrimary' ||
                    activatePage == '/musicFoundation/ListeningRhythmPitchPrimary' ||
                    activatePage == '/musicFoundation/SeniorListeningPitch' ||
                    activatePage == '/musicFoundation/SeniorListeningInterval' ||
                    activatePage == '/musicFoundation/SeniorListeningHarmony' ||
                    activatePage == '/musicFoundation/ListeningRhythmPitchSenior'
                "></KeyBoard>
            </div>
        </template>
        
    </t-dialog>
</template>

<script>
/* eslint-disable */
import NotePitchSingKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchSingKnowledge';
import NotePitchNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchNameKnowledge.vue';
import NotePitchTwelveEqualLawNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchTwelveEqualLawNameKnowledge.vue'
import NotePitchTwelveEqualLawSingKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NotePitchTwelveEqualLawSingKnowledge.vue'
import NoteRhythmNameKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteRhythmNameKnowledge.vue';
import TempoMarkKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/TempoMarkKnowledge.vue';
import ListeningRhythmExerciseKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/ListeningRhythmExerciseKnowledge.vue';
import NoteIntervalKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteIntervalKnowledge.vue';
import NoteInterval12LowKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteInterval12LowKnowledge.vue';
import NoteIntervalSeniorKnowledge from '@/components/MusicTheoryComponents/KnowledgeBox/NoteIntervalSeniorKnowledge.vue';
import KeyBoard from '@/components/MusicTheoryComponents/instruments/KeyBoard.vue';
import ModeNameKnowledge from './ModeNameKnowledge.vue';
import TonicSolfaKnowledge from './TonicSolfaKnowledge.vue';
import KnowledgeContainer from './KnowledgeContainer.vue';


export default {
    props: {
        isOpen: {
            type: Boolean,
            value: false
        },
        showOverlay: {
            type: Boolean,
            value: true
        },
        info: {
            type: Object,
            value: null
        }
    },
    
    watch: {
        isOpen: {
            immediate: true,
            handler(n) {
                this.isOpenTips = n
            }
        },
        $route: {
            immediate: true,
            handler(to) {
                console.log(to.path)
                this.activatePage = to.path
            }
        },
    },
    data() {
        return {
            isOpenTips: false,
            activatePage: '/'
        }
    },
    components: {
        NotePitchSingKnowledge,
        NotePitchNameKnowledge,
        NotePitchTwelveEqualLawNameKnowledge,
        NotePitchTwelveEqualLawSingKnowledge,
        NoteRhythmNameKnowledge,
        TempoMarkKnowledge,
        ListeningRhythmExerciseKnowledge,
        NoteIntervalKnowledge,
        NoteInterval12LowKnowledge,
        NoteIntervalSeniorKnowledge,
        KeyBoard,
        ModeNameKnowledge,
        TonicSolfaKnowledge,
        KnowledgeContainer
    },
    methods: {
        ternOff(){
            this.$emit('close')
        }
    }
}
</script>
<style>
.t-dialog {
    box-sizing: border-box;
    margin-right: 30px !important;
    border: none;
}

.knowledge-content-aside {
    width: 400px;
    display: flex;
    justify-content: center;
}
</style>