<template>
    <t-dialog v-model:visible="isOpenOrderDialog" :confirmBtn="null" :cancelBtn='null' @close="close" placement="center" width="480px" style="height: 600px;">
      <template #body>
        <ProductOrder v-if="orderinfo && orderinfo.order_id" width="100%" height="100%" :orderinfo="orderinfo"></ProductOrder>
      </template>
      </t-dialog>
  </template>
  
  <script>
  /* eslint-disable */ 
import ProductOrder from './orders/ProductOrder.vue'
import { userstore } from '@/store/userinfo';
  
  export default {
    components: {
      ProductOrder
    },
    data() {
      return {
        userstore: userstore(),
        orderinfo: null,
        isOpenOrderDialog: false
      }
    },
    watch: {
      "userstore.isOpenOrderDialog": {
        handler(n) {
          this.isOpenOrderDialog = n
        },
        immediate: true
      }, 
      "userstore.orderinfo": {
        handler(n) {
          this.orderinfo = n
        },
        immediate: true
      },   
    },
    methods: {
      close(){
        userstore().isOpenOrderDialog = false
      }
    }
  
  
  }
  </script>
  
  <style lang="less">
  
  
  
  </style>
  