<template>
  <div class="music-player">
    <div class="player-controls" v-if="currentSong">
      <h3>正在播放：{{ currentSong.name }}</h3>
      <audio ref="audioPlayer" :src="currentSong.url" @timeupdate="updateProgress" controls></audio>
      <div class="controls">
        <button @click="togglePlay">
          {{ isPlaying ? '暂停' : '播放' }}
        </button>
        <button @click="stop">停止</button>
        <input type="range" min="0" :max="duration" v-model="currentTime" @input="seek">
      </div>
    </div>

    <div class="playlist">
      <ul>
        <li v-for="(song, index) in songs" :key="index" @click="selectSong(song)"
          :class="{ active: currentSong?.url === song.url }">
          {{ song.name }}
        </li>
      </ul>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    list: {
      typeof: Array,
      default: []
    },
    isOpen: {
      typeof: Boolean,
      default: false
    }
  },
  watch: {
    list: {
      deep: true,
      immediate: true,
      handler: function (n) {
        if (n.length > 0) {
          this.songs = n
        }
      }
    },
    isOpen: {
      immediate: true,
      handler: function (n) {
        if (n) {
          this.isPlaying = false
          this.currentSong = null
        }
      }
    }
  },
  data() {
    return {
      songs: [],
      currentSong: null,
      isPlaying: false,
      duration: 0,
      currentTime: 0,
    }
  },
  methods: {
    selectSong(song) {
      this.currentSong = song
      this.$nextTick(() => {
        this.$refs.audioPlayer.play()
        this.isPlaying = true
      })
    },
    togglePlay() {
      if (!this.$refs.audioPlayer) return
      this.isPlaying = !this.isPlaying
      this.isPlaying
        ? this.$refs.audioPlayer.play()
        : this.$refs.audioPlayer.pause()
    },
    stop() {
      if (!this.$refs.audioPlayer) return
      this.$refs.audioPlayer.pause()
      this.$refs.audioPlayer.currentTime = 0
      this.isPlaying = false
      this.currentSong = null
    },
    updateProgress() {
      if (this.$refs.audioPlayer) {
        this.currentTime = this.$refs.audioPlayer.currentTime
        this.duration = this.$refs.audioPlayer.duration
      }
    },
    seek(event) {
      if (this.$refs.audioPlayer) {
        this.$refs.audioPlayer.currentTime = event.target.value
      }
    },
  },
  mounted() {
    if (this.$refs.audioPlayer) {
      this.$refs.audioPlayer.addEventListener('ended', () => {
        this.isPlaying = false
      })
    }
  },

}

</script>

<style scoped>
/* 保持原有样式不变 */
.music-player {
  max-width: 440px;
  margin: 20px auto;
  padding: 20px;
  background: #f5f5f5;
  border-radius: 10px;
}

.playlist ul {
  list-style: none;
  padding: 0;
}

.playlist li {
  padding: 10px;
  margin: 5px 0;
  background: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.playlist li:hover {
  background: #e0e0e0;
}

.playlist li.active {
  background: #2196F3;
  color: white;
}

.player-controls {
  margin-top: 20px;
  padding: 15px;
  background: white;
  border-radius: 8px;
}

.controls {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  align-items: center;
}

button {
  padding: 8px 15px;
  background: #2196F3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="range"] {
  flex-grow: 1;
}
</style>