<template>

    <div class="knowledge-base-box ">

        <div class="image-area">
            <t-image class="image"
                @click="getImage"
                src="https://www.duoyinchina.com/assits/basicMusicKnowledge/assits/knowledge/TempoMarkKnowledge.jpg"
                fit="cover" style="width: 420px; background-color: #ffffff;"></t-image>
        </div>
   
    </div>
    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
export default {
    data(){
        return {
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        getImage(e){
            this.PreImgSrc = e.srcElement.currentSrc;
            this.isOpenImgPreViewer = true
        }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>