/* eslint-disable */
export * from "./_musicTheoryRouterComponents.js";

const pagesBox = {
  notePitch: {
    menuType: "音高识别", // 用于导航选项卡识别
    pages: [
      {
        name: "NotePitchSing",
        path: "/musicFoundation/NotePitchSing", // router
        content: "唱名识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“唱名”相同的选项：", // 用于题干文案
        answerTitle: "曲谱上音符的唱名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "NotePitchName",
        path: "/musicFoundation/NotePitchName", // router
        content: "音名识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“音名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的音名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "NotePitchTwelveEqualLawSing",
        path: "/musicFoundation/NotePitchTwelveEqualLawSing", // router
        content: "唱名-带升降号", // router               // 章节
        questionTitle: "请选择与曲谱上音符“唱名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的唱名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "NotePitchTwelveEqualLawName",
        path: "/musicFoundation/NotePitchTwelveEqualLawName", // router
        content: "音名-带升降号", // router               // 章节
        questionTitle: "请选择与曲谱上音符“音名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的音名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  staveMarks: {
    menuType: "符号识别", // 用于导航选项卡识别
    pages: [
      {
        name: "NoteRhythmName",
        path: "/musicFoundation/NoteRhythmName",
        content: "音符识别",
        questionTitle: "请根据曲谱上音符的类型选择正确的选项：",
        answerTitle: "曲谱上音符的名称是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "TempoMark",
        path: "/musicFoundation/TempoMark", // router
        content: "速度术语", // router               // 章节
        questionTitle: "请根据曲谱选择正确的速度术语：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "SymbolRecognition",
        path: "/musicFoundation/SymbolRecognition",
        content: "符号识别",
        questionTitle: "请根据曲谱上标注的符号类型选择正确的选项：",
        answerTitle: "曲谱上标注的符号是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  noteRhythm: {
    menuType: "节奏识别", // 用于导航选项卡识别
    pages: [
      {
        name: "NoteRhythmCount",
        path: "/musicFoundation/NoteRhythmCount",
        content: "时值识别", // 章节
        questionTitle: "请根据曲谱上音符的“拍数”选择正确的选项：", // 题干文案
        answerTitle: "曲谱上音符的拍数是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "ListeningRhythmExercise",
        path: "/musicFoundation/ListeningRhythmExercise",
        content: "节奏听辩", // 章节
        questionTitle: "请选择与音频节奏一致的曲谱：", // 题干文案
        answerTitle: "曲谱上音符的拍数是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  noteInterval: {
    menuType: "音程识别", // 用于导航选项卡识别
    pages: [
      {
        name: "NoteInterval12Low",
        path: "/musicFoundation/NoteInterval12Low",
        content: "十二平均律",
        questionTitle: "请根据曲谱上音符之间的距离选择正确的选项：",
        answerTitle: "曲谱上音符之间的距离是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "NoteInterval",
        path: "/musicFoundation/NoteInterval",
        content: "音程基础",
        questionTitle: "请根据曲谱上音符之间的音程选择正确的选项：",
        answerTitle: "曲谱上音符之间的音程是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "NoteIntervalSenior",
        path: "/musicFoundation/NoteIntervalSenior",
        content: "音程高级",
        questionTitle: "请根据曲谱上音符之间的音程选择正确的选项：",
        answerTitle: "曲谱上音符之间的音程是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  staveMode: {
    menuType: "调式识别", // 用于导航选项卡识别
    pages: [
      {
        name: "ModeName",
        path: "/musicFoundation/ModeName",
        content: "调号识别",
        questionTitle: "请根据曲谱的调号选择正确的选项：",
        answerTitle: "曲谱的调号是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "TonicSolfa",
        path: "/musicFoundation/TonicSolfa", // router
        content: "音级识别", // router               // 章节
        questionTitle: "请根据曲谱选择正确的音级：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  listeningPitch: {
    menuType: "听音练习", // 用于导航选项卡识别
    pages: [
      {
        name: "ListeningPitchPrimary",
        path: "/musicFoundation/ListeningPitchPrimary", // router
        content: "自然音阶（听力练习）", // router                // 章节
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "ListeningRhythmPitchPrimary",
        path: "/musicFoundation/ListeningRhythmPitchPrimary",
        content: "自然音阶（旋律听辩）",
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "ListeningIntervalPrimary",
        path: "/musicFoundation/ListeningIntervalPrimary",
        content: "自然音阶（音程听辩）",
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "ListeningHarmonyPrimary",
        path: "/musicFoundation/ListeningHarmonyPrimary",
        content: "自然音阶（和声听辩）",
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "SeniorListeningPitch",
        path: "/musicFoundation/SeniorListeningPitch", // router
        content: "带升降号（听力练习）", // router                // 章节
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "ListeningRhythmPitchSenior",
        path: "/musicFoundation/ListeningRhythmPitchSenior",
        content: "带升降号（旋律听辩）",
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "SeniorListeningInterval",
        path: "/musicFoundation/SeniorListeningInterval", // router
        content: "带升降号（音程听辩）", // router                // 章节
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
      {
        name: "SeniorListeningHarmony",
        path: "/musicFoundation/SeniorListeningHarmony", // router
        content: "带升降号（和声听辩）", // router                // 章节
        questionTitle: "请听音频，并选择它的曲谱：",
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
      },
    ],
  },
  theoryPrimary: {
    menuType: "央院音基【初级】理论",
    isRequiredPaid: true,
    pages: [
      {
        name: "PitchRecognition",
        path: "/primary/PitchRecognition", // router
        content: "音高(唱名)识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“唱名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的唱名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "PitchNameRecognition",
        path: "/primary/PitchNameRecognition", // router
        content: "音高(音名)识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“音名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的音名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "NoteSequencePrimary",
        path: "/musicFoundation/NoteSequencePrimary",
        content: "模进练习",
        questionTitle: "请根据曲谱选择正确的模进选项：",
        answerTitle: "曲谱上音符之间的音程是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "SymbolRecognition",
        path: "/musicFoundation/SymbolRecognition",
        content: "符号识别",
        questionTitle: "请根据曲谱上标注的符号类型选择正确的选项：",
        answerTitle: "曲谱上标注的符号是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
        pro_id: "1717465119345345"
      },
      {
        name: "DynamicMarkings",
        path: "/musicFoundation/DynamicMarkings",
        content: "力度术语",
        questionTitle: "请根据曲谱上标注的力度术语选择正确的选项：",
        answerTitle: "曲谱上标注的力度术语是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: false,
        pro_id: "1717465119345345"
      },
      {
        name: "TempoMark",
        path: "/musicFoundation/TempoMark", // router
        content: "速度术语", // router               // 章节
        questionTitle: "请根据曲谱选择正确的速度术语：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
    ],
  },
  knowledgePrimary: {
    menuType: "央院音基【初级】常识",
    isRequiredPaid: true,
    pro_id: "1717465119345345",
    pages: [
      {
        name: "InstrumentRecognition",
        path: "/musicFoundation/InstrumentRecognition", // router
        content: "乐器识别", // router               // 章节
        questionTitle: "请根据图片中乐器的名称选择正确的选项：", // 题干文案
        answerTitle: "图片中乐器的名称是", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "InstrumentMusic",
        path: "/musicFoundation/InstrumentMusic", // router
        content: "听辩乐器", // router               // 章节
        questionTitle: "演奏这段音乐的主要乐器是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "AreaMusic",
        path: "/musicFoundation/AreaMusic", // router
        content: "听辩中国民歌", // router               // 章节
        questionTitle: "这段民歌属于：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "HumanMusic",
        path: "/musicFoundation/HumanMusic", // router
        content: "听辨歌唱声部及演唱形式", // router               // 章节
        questionTitle: "这段歌曲的演唱形式是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "ForeignMusic",
        path: "/musicFoundation/ForeignMusic", // router
        content: "听辩外国民歌", // router               // 章节
        questionTitle: "这段民歌属于：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
    ],
  },
  singPrimary: {
    menuType: "央院音基【初级】视唱",
    isRequiredPaid: true,
    pages: [
      {
        name: "PrimaryShiChangSheets",
        path: "/primary/PrimaryShiChangSheets", // router
        content: "视唱练习", // router               // 章节
        questionTitle: "请根据曲谱进行视唱，并选择该曲谱正确的名称：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
    ],
  },
  theorySenior: {
    menuType: "央院音基【中级】理论",
    isRequiredPaid: true,
    pages: [
      {
        name: "SeniorPitchSingRecognition",
        path: "/musicFoundation/SeniorPitchSingRecognition", // router
        content: "唱名识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“唱名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的唱名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "SeniorPitchNameRecognition",
        path: "/musicFoundation/SeniorNamePitchRecognition", // router
        content: "音名识别", // router               // 章节
        questionTitle: "请选择与曲谱上音符“音名”相同的选项：", // 题干文案
        answerTitle: "曲谱上音符的音名是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "SeniorModeNameRecognition",
        path: "/musicFoundation/SeniorModeNameRecognition", // router
        content: "调号识别", // router               // 章节
        questionTitle: "请根据曲谱上的调号选择响应的调：", // 题干文案
        answerTitle: "曲谱上调号是：", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      }
    ],
  },
  knowledgeSenior: {
    menuType: "央院音基【中级】常识",
    isRequiredPaid: true,
    pro_id: "1717465119345345",
    pages: [
      {
        name: "SeniorInstrumentRecognition",
        path: "/musicFoundation/SeniorInstrumentRecognition", // router
        content: "识别乐器", // router               // 章节
        questionTitle: "请根据图片中乐器的名称选择正确的选项：", // 题干文案
        answerTitle: "图片中乐器的名称是", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "SeniorInstrumentMusic",
        path: "/musicFoundation/SeniorInstrumentMusic", // router
        content: "听辩乐器", // router               // 章节
        questionTitle: "演奏这段音乐的主要乐器是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorHumanMusic",
        path: "/musicFoundation/SeniorHumanMusic", // router
        content: "听辨演唱形式与声乐类别", // router               // 章节
        questionTitle: "这段歌曲的演唱形式是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "SeniorAreaMusic",
        path: "/musicFoundation/SeniorAreaMusic", // router
        content: "听辩中国民族乐种", // router               // 章节
        questionTitle: "这首乐曲所属乐种是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorIChineseMusicStyleRecognition",
        path: "/musicFoundation/SeniorIChineseMusicStyleRecognition", // router
        content: "识别中国民族乐种", // router               // 章节
        questionTitle: "图片中的乐种是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorTypeMusic",
        path: "/musicFoundation/SeniorTypeMusic", // router
        content: "听辩歌曲的类别", // router               // 章节
        questionTitle: "这首歌曲的类别是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorListeningChinesePopMusic",
        path: "/musicFoundation/SeniorListeningChinesePopMusic", // router
        content: "听辩中国流行音乐", // router               // 章节
        questionTitle: "这段首乐曲的曲名是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorListeningForeignMusic",
        path: "/musicFoundation/SeniorListeningForeignMusic", // router
        content: "听辩国外流行音乐", // router               // 章节
        questionTitle: "这段首乐曲的曲名是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorListeningForeignInstrumentMusic",
        path: "/musicFoundation/SeniorListeningForeignInstrumentMusic", // router
        content: "听辩国外器乐乐曲", // router               // 章节
        questionTitle: "这段首乐曲的曲名是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
      {
        name: "SeniorMusicFormRecognition",
        path: "/musicFoundation/SeniorMusicFormRecognition", // router
        content: "听辩乐曲的曲式结构", // router               // 章节
        questionTitle: "这段首乐曲的曲式结构是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345",
      },
    ],
  },
  knowledgeHigh: {
    menuType: "央院音基【高级】常识",
    isRequiredPaid: true,
    pro_id: "1717465119345345",
    pages: [
      {
        name: "HighListeningDanceMusic",
        path: "/musicFoundation/HighListeningDanceMusic", // router
        content: "听辩舞剧音乐", // router               // 章节
        questionTitle: "这是哪部舞剧中的音乐：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "HighListeningFilmMusic",
        path: "/musicFoundation/HighListeningFilmMusic", // router
        content: "听辩影视音乐", // router               // 章节
        questionTitle: "这是哪部影视作品中的音乐：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "HighChineseRecognition",
        path: "/musicFoundation/HighChineseRecognitionc", // router
        content: "识别中国音乐家", // router               // 章节
        questionTitle: "图片中的音乐家是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "HighForeignRecognition",
        path: "/musicFoundation/HighForeignRecognition", // router
        content: "识别外国音乐家", // router               // 章节
        questionTitle: "图片中的音乐家是：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
      {
        name: "HighListeningChineseSpeckingMusic",
        path: "/musicFoundation/HighListeningChineseSpeckingMusic", // router
        content: "听辩中国说唱音乐", // router               // 章节
        questionTitle: "这段说唱唱段属于什么曲种：", // 题干文案
        answerTitle: "", // 答题卡文案
        description: "共10题 每题10分", //  问题介绍文案
        isActived: false,
        isRequiredPaid: true,
        pro_id: "1717465119345345"
      },
    ],
  },
};

const pages = () => {
  // 加工下pagesBox的数据格式，转成eeducationPages和router需要的类型

  let box = [];

  for (let key in pagesBox) {
    box.push(
      ...pagesBox[key].pages.map((item, index) => {
        item.menuType = pagesBox[key].menuType;
        item.menuValue = index + 1 + "";
        item.menuName = key;
        return item;
      })
    );
  }

  return box;
};

export const educationPages = pages();
export const educationPagesSide = pagesBox; // 侧边导航用的
