<template>
    <div style="width: 480px;">
        <iframe v-if="url" allow-same-origin sandbox="allow-scripts allow-top-navigation" width="400" height="900"
            frameBorder="0" allowTransparency="true" :src="url"></iframe>

        <t-space v-else-if="info && info.askfor.includes('识别')" :break-line="true"
            :style="{ height: '800px', 'overflow-y': 'scroll' }">
            <t-card v-for="(item, index) in imageList" :key="index" :title="item.title" :bordered="false" hover-shadow
                :style="{ width: '380px' }">
                <t-image :src="item.src" style="width: 100%; cursor: pointer;" :lazy="true" @click="getImage" />
            </t-card>
        </t-space>

        <AudioList v-else-if="info && info.askfor.includes('听辩')" :list="musicList" :isOpen="isOpen"></AudioList>
    </div>
    <t-image-viewer
      v-model:visible="isOpenImgPreViewer"
      :images="[PreImgSrc]"
      @close="isOpenImgPreViewer=false"
    >
    </t-image-viewer>

</template>

<script>
import { get } from '@/tools/tool'
import AudioList from './AudioList.vue';

export default {
    props: {
        activatePage: {
            typeof: String,
            default: ''
        },
        info: {
            typeof: Object,
            default: null
        },
        isOpen: {
            typeof: Boolean,
            default: false
        }
    },
    components: {
        AudioList
    },
    watch: {
        activatePage: {
            immediate: true,
            handler: function (n) {
                if (n == '/musicFoundation/DynamicMarkings') {
                    this.url = "https://www.duoyinchina.com/assits/html/musicKnowledge/musicFoundation_DynamicMarkings.html"
                }
            }
        },
        info: {
            immediate: true,
            handler: async function (n) {
                if (n && n.askfor) {
                    if (n.askfor.includes('识别')) {
                        this.getImgList(n.askfor)
                    } else if (n.askfor.includes('听辩')) {
                        this.getMusicList(n.askfor)
                    }
                }
            },
            deep: true
        }
    },
    data() {
        return {
            url: '',
            imageList: [],
            musicList: [],
            wavesurfer: null,
            PreImgSrc: '',
            isOpenImgPreViewer: false
        }
    },
    methods: {
        async getImgList(dir) {
            let list = await get('/web/MusicTheory/sheets', 'fileName=' + dir)
            this.imageList = list.map(item => {
                return {
                    src: "https://www.duoyinchina.com/web/MusicTheory/image/" + dir + '/' + item,
                    title: item.split('.')[0].replace(/\d/g, '')
                }
            });
        },
        async getMusicList(dir) {
            let list = await get('/web/MusicTheory/sheets', 'fileName=' + dir)
            this.musicList = list.map(item => {
                return {
                    url: "https://www.duoyinchina.com/web/MusicTheory/audio/" + dir + '/' + item,
                    name: item.split('.')[0].replace(/\d/g, ''),
                }
            });
        },
        getImage(e) {
      this.PreImgSrc = e.srcElement.currentSrc;
      this.isOpenImgPreViewer = true
    }
    }
}
</script>

<style lang="less" scoped>
@import url(./style/knowledgeBase.less);
</style>
